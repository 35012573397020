/* Banner do mês */
.monthBanner {
    color: white;
    background: linear-gradient(90deg, #1e4b84, #173f74);
    width: 80%;
    padding: 1% 2%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 20px auto;
}

/* Botões de ícone */
.button-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.button-icon i {
    color: #173f74;
    font-size: 24px;
}

.button-icon:disabled i {
    color: #b3b3b3;
}

.button-icon:hover {
    transform: scale(1.1);
}




.schedule-page {
    position: relative;
    /* Para permitir o posicionamento absoluto do menu */
    padding-bottom: 60px;
    /* Espaço para o menu na parte inferior */
}


.menu {
    position: fixed;
    /* Fixa o menu no fundo da página */
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* Distribui os itens igualmente */
    background-color: #173f74;
    /* Cor de fundo branca */
    border-top: 1px solid #173f74;
    /* Borda superior cinza claro */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
    padding: 10px;
    z-index: 1000;
    /* Garante que o menu fique sobre outros elementos */
}

.menu-button {
    background: none;
    border: none;
    color: #f8f8f8;
    /* Cor do texto */
    font-size: 20px;
    /* Tamanho do ícone */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.menu-button:hover {
    color: #007bff;
    /* Cor do texto ao passar o mouse */
}

.menu-button .icon {
    font-size: 24px;
    /* Tamanho do ícone */
}

.menu-button span {
    font-size: 12px;
    /* Tamanho do texto abaixo do ícone */
    color: #666;
    /* Cor do texto abaixo do ícone */
}


.addEvent {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    width: 220px;
}

.addFunctions {
    position: fixed;
    bottom: 0;
    /* Ajusta a posição para a parte inferior da tela */
    left: 0;
    /* Ajusta a posição para a borda esquerda da tela */
    width: 100%;
    /* Faz com que o componente ocupe toda a largura da tela */
    z-index: 2000;
    display: flex;
    justify-content: space-around;
    /* Espaça os itens igualmente ao longo da largura */
    padding: 0 20px;
    /* Adiciona algum padding nas laterais para evitar que os itens fiquem colados às bordas */
    box-sizing: border-box;
    /* Inclui o padding no cálculo da largura total */
}

/* Centralizando conteúdo */
.centerText {
    text-align: center;
}

/* Estilos adicionais */
.printable-content {
    @media print {
        display: none;
    }
}


.no-scroll {
    overflow: hidden;
}