/* Tabela */
table {
    border-collapse: separate; /* Altere de collapse para separate */
    border-spacing: 0 8px; /* Adicione espaçamento entre as linhas */
    width: 90%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black;
}

th,
td {
    padding: 12px;
    text-align: left;
    border: 1px solid #00000023; /* Adicione uma borda */
}

tr {
    background-color: white;
}

tr:hover td {
    background-color: rgba(0, 0, 0, 0.05);
}

th {
    background-color: #173f74;
    color: white;
    font-weight: bold;
}

td {
    background-color: transparent;
}

@media screen and (max-width: 768px) {
    .addEvent {
        flex-direction: column;
        width: auto;
        left: 10%;
        transform: translateX(0);
    }

    table,
    th,
    td {
        display: block;
        width: 100vw;
    }

    tr {
        display: block;
        margin-bottom: 10px;
        border: 1px solid #00000023; /* Adicione uma borda ao redor de cada linha */
        width: 100vw;
    }

    th,
    td {
        box-sizing: border-box;
        width: 100%;
        text-align: left;
        display: block; /* Cada célula ocupará toda a largura da linha */
    }

    th {
        text-align: center;
    }

    td {
        text-align: center;
        padding: 10px 5px;
    }
}