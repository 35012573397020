/* Adicione essas classes ao seu arquivo CSS existente */

.google-search-bar {
    display: flex;
    justify-content: center;
    padding: 16px;
}

.search-container {
    display: flex;
    align-items: center;
    background-color: #f1f3f4;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    padding: 8px;
    width: 400px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    /* Adiciona uma sombra suave */
}

.search-icon {
    color: #70757a;
    margin-right: 8px;
}

input {
    border: none;
    outline: none;
    flex: 1;
    background: none;
    padding: 8px;
}

.search-button,
.clear-button {
    background-color: #4285f4;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    margin-left: 8px;
    cursor: pointer;
    outline: none;
}

.clear-button {
    background-color: #dadce0;
    color: #1a73e8;
}

.suggestion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    background-color: #fff;
    color: black;
    z-index: 1;
    text-align: start;
    border-radius: 30px;
}

.suggestion-list li {
    padding: 10px;
    cursor: pointer;
}

.suggestion-list li:hover {
    background-color: #f4f4f4;
}



/* Adicione mais estilos conforme necessário */