/* form.css */

/* Estilo do Overlay do Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    padding: 20px;
}

/* Estilo do Conteúdo do Modal */
.modal-content {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Estilo dos botões primário e secundário */
.btn {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.btn-true {
    background-color: #007bff;
    color: #fff;
}

.btn-true:hover {
    background-color: #0056b3;
}

.btn-false {
    background-color: #ff0000;
    color: #fff;
}

.btn-false:hover {
    background-color: #b30000;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

/* Responsividade */
@media (max-width: 600px) {
    .modal-content {
        padding: 20px;
    }

    .form-actions {
        flex-direction: column;
    }

    .save-button,
    .cancel-button {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
